import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class MethodService {

  readonly uri = 'secondary/method';

  constructor(private http:HttpClient) {}

  getMethods(dataset: string): Observable<Method[]> {
    return this.http.get('secondary/method/' + dataset)
      .pipe(
        map(o => <Method[]> o)
      );
  }

  deleteMethod(dataset: string, uuid: string) {
    return this.http.delete('secondary/method/' + dataset + '/' + uuid);
  }

  computeLcia(dataset: string, uuid: string) {
    return this.http.get('secondary/method/' + dataset + '/' + uuid + '/compute');
  }

  downloadLcias(dataset: string) {
    return this.http.get('secondary/method/' + dataset + '/lcias', { responseType: 'text', headers: { 'Accept': 'text/csv' } });
  }
}

export class Method {
  public uuid: string;
  public metadata: any;
  public lciaState: string;
}
