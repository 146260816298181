import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {Dataset} from "./dataset/dataset.service";

@Injectable()
export class GlobalService {
  private dataset: Subject<string> = new Subject();

  public setDataset(dataset: string) {
    this.dataset.next(dataset);
  }

  public getDataset(): Observable<string> {
    return this.dataset;
  }
}
