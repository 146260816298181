import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, Subject} from "rxjs";
import {map, tap} from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DatasetService {

  readonly uri = 'secondary/dataset';

  public datasetUpdate = new Subject<string>();

  constructor(private http:HttpClient) {}

  getDatasets(): Observable<Dataset[]> {
    return this.http.get('secondary/dataset')
      .pipe(
        map(o => <Dataset[]> o)
      );
  }

  updateDataset(dataset: Dataset) {
    return this.http.put('secondary/dataset', dataset).pipe(tap(o => this.datasetUpdate.next('')));
  }

  newDataset(dataset: Dataset) {
    return this.http.post('secondary/dataset', dataset).pipe(tap(o => this.datasetUpdate.next('')));
  }

  deleteDataset(uuid: string) {
    return this.http.delete('secondary/dataset/' + uuid).pipe(tap(o => this.datasetUpdate.next('')));
  }
}

export class Dataset {
  public uuid: string;
  public name: string;
  public description: string;
  public beta: boolean = false;
}
