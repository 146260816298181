import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class ImportsService {

  readonly uri = 'secondary/import';

  constructor(private http: HttpClient) {
  }

  getImports(dataset: string): Observable<Import[]> {
    return this.http.get('secondary/import/' + dataset).pipe(
      map(res => {
        var imports: Import[] = <Import[]> res;
        imports.forEach(i => i.date = new Date(i.date));
        return imports;
      })
    );
  }

  getImportDetail(dataset: string, importId: string): Observable<ImportDetail> {
    return this.http.get('secondary/import/' + dataset + "/" + importId).pipe(
      map(res => {
        const det = <ImportDetail> res;
        det.date = new Date(det.date);
        return det;
      })
    );
  }

  newImport(dataset: string, request: ImportRequest): Observable<Import> {
    return this.http.post('secondary/import/' + dataset, request).pipe(
      map(res => {
        const imp = <Import> res;
        imp.date = new Date(imp.date);
        return imp;
      })
    );
  }

  deleteImport(dataset: string, id: string) {
    return this.http.delete('secondary/import/' + dataset + '/' + id);
  }
}

export class Import {

  public id: string;
  public date: Date;
  public type: string;
  public status: string;
  public phase: string;

}

export class ImportStatus {

  public timestamp: Date;
  public status: string;
  public phase: string;

}

export class ImportStatusDetailElement {

  public count: number;
  public skipped: number;
  public imported: number;

}

export class MissingFlow {

  public flowType: string;
  public flowId: string;
  public sourceId: string;
  public descripiion: string;

}

export class ImportStatusDetail {

  public timestamp: Date;
  public processes: ImportStatusDetailElement;
  public methods :ImportStatusDetailElement;
  public flows: ImportStatusDetailElement;
  public missingFlows: MissingFlow[];

}

export class ImportDetail {

  public id: string;
  public date: Date;
  public type: string;
  public source: string;
  public statuses: ImportStatus[];
  public statusDetail: ImportStatusDetail;

}

export class ImportRequest {
  public source: string;
  public type: string;
  public processes: ImportItem = new ImportItem();
  public flows: ImportItem = new ImportItem();
  public methods: ImportItem = new ImportItem();
  public calculateLcias: boolean = true;
  public categoryMapping: string;
  public flowMapping: string;
  public sourceMetadata: string;
  public methodsFilter: string;
  public processExternalLinksMapping: string;
}

export class ImportItem {
  public include: boolean = true;
  public overwrite: boolean = false;
}
