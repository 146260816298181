import {DataSource} from "@angular/cdk/table";
import {BehaviorSubject, Observable, of} from "rxjs";
import {CollectionViewer} from "@angular/cdk/collections";
import {RefTablesService} from "./ref-tables.service";
import {catchError, finalize} from "rxjs/operators";

export class ReftablesDatasource implements DataSource<any> {

    private lessonsSubject = new BehaviorSubject<Object[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private tableService: RefTablesService) {}

    connect(collectionViewer: CollectionViewer): Observable<Object[]> {
      return this.lessonsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
      this.lessonsSubject.complete();
      this.loadingSubject.complete();
    }

    loadTable(dataset: string) {
      this.loadingSubject.next(true);

      this.tableService.getTables(dataset)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(imports => this.lessonsSubject.next(imports));
    }
  }
