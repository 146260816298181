import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RefTableAndValues, RefTablesService} from "./ref-tables.service";
import {GlobalService} from "../global.service";
import {HotTableComponent} from "@handsontable/angular";
import {MatSnackBar} from "@angular/material";

@Component({
  selector: 'import-detail',
  templateUrl: './ref-table-detail.component.html',
  styleUrls: ['./ref-table-detail.component.scss']
})
export class RefTableDetailComponent implements OnInit {

  private sub: any;
  private dataset: string;
  private id: string;
  table: RefTableAndValues;
  loading = false;
  data = [];
  columns = [];
  settings = {
    manualColumnResize: true,
    manualRowResize: true
  };
  @ViewChild('hotTable') hotTable: HotTableComponent;
  @ViewChild('hotContainer') hotContainer: HTMLDivElement;


  constructor(private globalService: GlobalService, private tablesService: RefTablesService, private route: ActivatedRoute, private router: Router,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.dataset = params['id'];
      this.globalService.setDataset(this.dataset);
      this.id = params['tableId'];
      this.refresh();
    });
  }

  private refresh() {
    this.loading = true;
    this.tablesService.getTableAndValues(this.dataset, this.id).subscribe(i => {
      this.table = i;
      this.data = this.table.values;
      if (!this.data) {
        this.data = [];
      }
      var lastRow = {};
      this.columns = [];
      for (const column of this.table.descriptor.columns) {
        this.columns.push({
          'type': column.type.toLowerCase(),
          'data': column.name,
          'title': column.name,
          'numericFormat': {
            'pattern': '0.[00000000000000]'
          }
        });
        Reflect.set(lastRow, column.name, null);
      }
      this.data.push(lastRow);
      this.loading = false;
    });
  }

  saveTable() {
    this.loading = true;
    this.tablesService.saveTableValues(this.dataset, this.table.id, this.data)
      .subscribe(f => {
        this.refresh();
        this.snackBar.open("Table saved !", null, {
          duration: 2000
        })
      });
  }

  deleteTable() {
    this.tablesService.deleteTable(this.dataset, this.table.id)
      .subscribe(t => this.router.navigate(['tables', this.dataset]));
  }
}
