import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatExpansionPanel, MatHorizontalStepper} from "@angular/material";
import {interval} from "rxjs/internal/observable/interval";
import {GlobalService} from "../../global.service";
import {Referential, ReferentialsService} from "./referentials.service";

@Component({
  selector: 'referentials',
  templateUrl: './referentials.component.html',
  styleUrls: ['./referentials.component.css']
})
export class ReferentialsComponent implements OnInit, OnDestroy {

  public referentials: Referential[];

  private sub: any;
  newId: string = '';
  displayedColumns: string[] = ['name', 'currentVersion', 'lastVersion', 'actions'];
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @ViewChild('newImportPanel') newImportPanel: MatExpansionPanel;

  constructor(private referentialsService: ReferentialsService, private route: ActivatedRoute, private router: Router, private globalService: GlobalService) {
  }

  ngOnInit() {
    this.globalService.setDataset(null);
    this.sub = interval(5000).subscribe(() => this.refresh());
    this.refresh();
  }

  refresh() {
    this.referentialsService.getReferentials().subscribe(l => this.referentials = l);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  showReferential(row: Referential) {
    this.router.navigate(['referentials', row.id]);
  }

  startImport() {
    this.referentialsService.newReferential(this.newId).subscribe(i => this.refresh());
    this.newImportPanel.close();
  }

  delete(row: Referential) {
    if (confirm('Are you sure that you want to delete referential ' + row.id + ' ?')) {
      this.referentialsService.deleteReferential(row.id)
        .subscribe(d => this.refresh());
    }
  }

  isValidId(id: string) {
    if (id == null) {
      return false;
    }
    return id.match(/^[A-Za-z0-9-_]+$/);
  }
}
