import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class RefTablesService {

  readonly uri = 'secondary/table';

  constructor(private http: HttpClient) {
  }

  getTables(dataset: string, tableType?: string): Observable<RefTable[]> {
    let url = 'reftable/' + dataset;
    if (tableType) {
      url += '?type=' + tableType;
    }
    return this.http.get(url).pipe(
      map(res => {
        var tables: RefTable[] = <RefTable[]> res;
        return tables;
      })
    );
  }

  getTableAndValues(dataset: string, id: string): Observable<RefTableAndValues> {
    return this.http.get('reftable/' + dataset + '/' + id).pipe(
      map(res => {
        var table: RefTableAndValues = <RefTableAndValues> res;
        return table;
      })
    );
  }

  saveTableValues(dataset: string, id: string, values: any[]) {
    return this.http.put('reftable/' + dataset + '/' + id, values);
  }

  getTableDescriptors(dataset: string): Observable<RefTableDescriptor[]> {
    return this.http.get('reftable/' + dataset + '/descriptors').pipe(
      map(res => {
        var descriptors: RefTableDescriptor[] = <RefTableDescriptor[]> res;
        return descriptors;
      })
    );
  }

  newTable(dataset: string, type: string, name: string): Observable<RefTable> {
    return this.http.post('reftable/' + dataset, {
      'type': type,
      'name': name
    }).pipe(
      map(res => {
        return <RefTable> res;
      })
    );
  }

  deleteTable(dataset: string, id: string) {
    return this.http.delete('reftable/' + dataset + '/' + id);
  }
}

export class RefTable {

  public id: string;
  public type: string;
  public name: string;

}

export class RefTableAndValues extends RefTable {

  public values: any;
  public descriptor: RefTableDescriptor;

}

export class RefTableDescriptorColumn {

  public name: string;
  public type: string;

}

export class RefTableDescriptor {

  public type: string;
  public name: string;
  public columns: RefTableDescriptorColumn[];

}
