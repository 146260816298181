import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ProcessService {

  readonly uri = 'secondary/process/';

  constructor(private http:HttpClient) {}

  search(dataset: string, search: ProcessSearch): Observable<Process[]> {
    return this.http.post(this.uri + dataset, search)
      .pipe(
        map(o => <Process[]> o)
      );
  }

  deleteProcess(dataset: string, uuid: string): Observable<any> {
    return this.http.delete(this.uri + dataset + '/' + uuid);
  }
}

export class Process {
  public uuid: string;
  public metadata: any;
  public activity: string;
}

export class ProcessSearch {
  public uuid: string;
  public name: string;
}
