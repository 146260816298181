import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Dataset} from "./dataset.service";

@Component({
  selector: 'dataset-dialog',
  templateUrl: 'dataset-dialog.html',
})
export class DatasetDialog {

  constructor(
    public dialogRef: MatDialogRef<DatasetDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Dataset) {}

}
