import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GlobalService} from "./global.service";
import {MatSelectChange, MatSidenav} from "@angular/material";
import {Dataset, DatasetService} from "./dataset/dataset.service";
import {KeycloakService} from "keycloak-angular";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  dataset: string;
  datasets: Dataset[];
  datasetMap = [];

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(private globalService: GlobalService, private cdRef: ChangeDetectorRef, private datasetService: DatasetService, private router: Router) {

  }

  ngOnInit(): void {
    this.datasetService.datasetUpdate.subscribe(o => this.refreshDatasets());
    this.refreshDatasets();
  }

  private refreshDatasets() {
    this.datasetService.getDatasets().subscribe(l => {
      this.datasets = l;
      for (const d of l) {
        this.datasetMap[d.uuid] = d.name;
      }
    });

  }

  setDataset(dataset: string, changePage: boolean) {
    this.sidenav.opened = true;
    if (this.dataset == dataset) {
      return;
    }
    this.dataset = dataset;
    if (changePage) {
      this.router.navigate(['imports', dataset]);
    }
  }

  ngAfterViewChecked() {
    this.globalService.getDataset().subscribe(d => this.setDataset(d, false));
    this.cdRef.detectChanges();
  }


}
