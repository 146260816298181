import {Component, ViewChild} from '@angular/core';
import {Dataset, DatasetService} from "./dataset.service";
import {MatDialog, MatExpansionPanel} from "@angular/material";
import {DatasetDialog} from "./dataset-dialog";
import {GlobalService} from "../global.service";

@Component({
  selector: 'datasets',
  templateUrl: './choosedataset.component.html',
})
export class DatasetListComponent {

  public datasets: Dataset[];

  public newDataset: Dataset = new Dataset();

  @ViewChild('newDatasetPanel') newDatasetPanel: MatExpansionPanel;

  displayedColumns: string[] = ['name', 'description', 'beta', 'actions'];

  constructor(private datasetService: DatasetService, private globalService: GlobalService, public dialog: MatDialog) {
    this.refresh();
  }

  private refresh() {
    this.datasetService.getDatasets()
      .subscribe(d => this.datasets = d)
  }

  selectDataset(row: Dataset) {
    this.globalService.setDataset(row.uuid);
  }

  deleteDataset(row: Dataset) {
    if (confirm("Are you sure to delete "+ row.name)) {
      this.datasetService.deleteDataset(row.uuid)
        .subscribe(d => this.refresh());
    }
  }

  updateDataset(dataset: Dataset) {
    this.openDialog(dataset)
  }

  openDialog(dataset: Dataset): void {
    const dialogRef = this.dialog.open(DatasetDialog, {
      width: '250px',
      data: dataset
    });

    dialogRef.afterClosed().subscribe(result => {
      if (dataset.uuid) {
        this.datasetService.updateDataset(dataset)
          .subscribe(d => this.refresh());
      }
    });
  }

  createDataset() {
    this.datasetService.newDataset(this.newDataset)
      .subscribe(d => {
        this.refresh()
        this.newDataset = new Dataset();
        this.newDatasetPanel.close();
      });
  }
}



