import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RefTable, RefTableDescriptor, RefTablesService} from "./ref-tables.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ReftablesDatasource} from "./reftables.datasource";
import {MatExpansionPanel, MatHorizontalStepper} from "@angular/material";
import {interval} from "rxjs/internal/observable/interval";
import {GlobalService} from "../global.service";

@Component({
  selector: 'tables',
  templateUrl: './ref-tables.component.html',
  styleUrls: ['./ref-tables.component.css']
})
export class RefTablesComponent implements OnInit, OnDestroy {

  public imports;

  private sub: any;
  private dataset: string;
  private newTable: RefTable = new RefTable();
  descriptors: RefTableDescriptor[];
  descTypeNames = [];

  dataSource: ReftablesDatasource;
  displayedColumns: string[] = ['type', 'name'];

  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @ViewChild('newImportPanel') newImportPanel: MatExpansionPanel;

  constructor(private tablesService: RefTablesService, private route: ActivatedRoute, private router: Router, private globalService: GlobalService) {
  }

  ngOnInit() {
    this.dataSource = new ReftablesDatasource(this.tablesService);
    this.sub = this.route.params.subscribe(params => {
      this.dataset = params['id'];
      this.globalService.setDataset(this.dataset);
      this.refresh();
      this.tablesService.getTableDescriptors(this.dataset).subscribe(descriptors => {
        for (let descriptor of this.descriptors = descriptors) {
          this.descTypeNames[descriptor.type] = descriptor.name;
        };
      });
    });
  }

  getTypeName(type: string) {
    let val = this.descTypeNames[type];
    return (val) ? val : type;
  }

  createTable() {
    this.tablesService
      .newTable(this.dataset, this.newTable.type, this.newTable.name)
      .subscribe(t => this.router.navigate(['tables', this.dataset, t.id]));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getRecord(row: RefTable) {
    this.router.navigate(['tables', this.dataset, row.id]);
  }

  refresh() {
    this.dataSource.loadTable(this.dataset);
  }

}
