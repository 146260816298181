import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class ReferentialsService {

  readonly uri = 'instantlca/referential';

  constructor(private http: HttpClient) {
  }

  getReferentials(): Observable<Referential[]> {
    return this.http.get(this.uri).pipe(map(res => <Referential[]>res));
  }

  getReferential(id: string): Observable<Referential> {
    return this.http.get(this.uri + '/' + id).pipe(map(res => <Referential>res));
  }

  newReferential(id: string): Observable<Referential> {
    return this.http.post(this.uri, id).pipe(map(res => <Referential>res));
  }

  deleteReferential(id: string) {
    return this.http.delete(this.uri + '/' + encodeURIComponent(id));
  }

  addVersion(id: string, url: string): Observable<Referential> {
    return this.http.post(this.uri + '/' + encodeURIComponent(id), url).pipe(map(res => <Referential>res));
  }

  addSpecificVersion(id: string, version: number, url: string): Observable<Referential> {
    return this.http.post(this.uri + '/' + encodeURIComponent(id) + '/' + version, url).pipe(map(res => <Referential>res));
  }

  deleteVersion(id: string, version: number) {
    return this.http.delete(this.uri + '/' + encodeURIComponent(id) + '/' + version);
  }

  setActiveVersion(id: string, version: number): Observable<Referential> {
    return this.http.put(this.uri + '/' + encodeURIComponent(id) + '/' + version, {}).pipe(map(res => <Referential>res));
  }

  getPilotDebugs(id: string): Observable<PilotDebug[]> {
    return this.http.get(this.uri + '/' + encodeURIComponent(id) + '/pilot-debugs').pipe(map(res => <PilotDebug[]>res));
  }

  getPilotDebug(id: string, debugId: string) {
    return this.http.get(this.uri + '/' + encodeURIComponent(id) + '/pilot-debug/' + debugId + '/model', {
      responseType: 'text',
      headers: {'Accept': 'application/xml'}
    });
  }

  getPilotDebugIps(id: string, debugId: string) {
    return this.http.get(this.uri + '/' + encodeURIComponent(id) + '/pilot-debug/' + debugId + '/ips', {
      responseType: 'text',
      headers: {'Accept': 'text/csv'}
    });
  }

  getModelFile(id: string, version: number) {
    return this.http.get(this.uri + '/' + encodeURIComponent(id) + '/' + version, {
      responseType: 'text',
      headers: {'Accept': 'application/xml'}
    });
  }

}

export class Referential {

  public id: string;
  public currentVersion: number;
  public versions: ReferentialVersion[];
  public currentVersionStatus: string;
  public lastVersionStatus: number;

}

export class ReferentialVersion {

  public version: number;
  public url: string;
  public creationDate: Date;
  public dataset: string;
  public datasetName: string;
  public status: string;
  public errors: string[];

}

export class PilotDebug {

  public id: string;
  public timestamp: Date;
  public version: number;
  public errors: string[];

}
