import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {interval} from 'rxjs/internal/observable/interval';
import {PilotDebug, Referential, ReferentialsService, ReferentialVersion} from './referentials.service';
import {GlobalService} from '../../global.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatHorizontalStepper} from "@angular/material/stepper";
import {MatExpansionPanel} from "@angular/material/expansion";

@Component({
  selector: 'referential-detail',
  templateUrl: './referential-detail.component.html',
  styleUrls: ['./referential-detail.component.css']
})
export class ReferentialDetailComponent implements OnInit {

  private sub: any;
  private id: string;
  newUrl = '';
  newVersion = null;
  referential: Referential;
  displayedColumns: string[] = ['version', 'date', 'dataset', 'status', 'errors', 'download', 'actions'];
  modelDebugs: PilotDebug[];
  private modelFiles: { [version: number]: FileDownload; } = { };
  private modelDebugFiles: { [id: string]: FileDownload; } = { };
  private modelDebugIps: { [id: string]: FileDownload; } = { };
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @ViewChild('newImportPanel') newImportPanel: MatExpansionPanel;

  constructor(private globalService: GlobalService,
              private referentialsService: ReferentialsService,
              private route: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.globalService.setDataset(null);
      this.refresh();
      interval(5000).subscribe(() => this.refresh());
    });
  }

  private refresh() {
    this.referentialsService.getReferential(this.id).subscribe(r => {
      this.setReferential(r);
    });
    this.referentialsService.getPilotDebugs(this.id).subscribe(d => this.modelDebugs = d);
  }

  private setReferential(r) {
    this.referential = r;
    r.versions.sort((v1, v2) => v2.version - v1.version);
  }

  showErrors(version: ReferentialVersion) {
    this.dialog.open(ReferentialVersionErrorsDialog, {data: version.errors});
  }

  showDebugErrors(debug: PilotDebug) {
    this.dialog.open(ReferentialVersionErrorsDialog, {data: debug.errors});
  }

  activate(version: ReferentialVersion) {
    this.referentialsService
      .setActiveVersion(this.id, version.version)
      .subscribe(r => this.refresh());
  }

  startImport() {
    function afterImport(r) {
      this.newVersion = null;
      this.newUrl = '';
      this.stepper.reset();
      this.setReferential(r);
    }

    if (this.newVersion == null || this.newVersion === '') {
      this.referentialsService
        .addVersion(this.referential.id, this.newUrl)
        .subscribe(r => afterImport.call(this, r));
    } else {
      for (const v of this.referential.versions) {
        if (v.version == this.newVersion) {
          window.alert('Version already exists');
          return;
        }
      }
      this.referentialsService
        .addSpecificVersion(this.referential.id, this.newVersion, this.newUrl)
        .subscribe(r => afterImport.call(this, r));
    }
    this.newImportPanel.close();
  }

  delete(version: ReferentialVersion) {
    if (confirm('Are you sure that you want to delete version ' + version.version + ' ?')) {
      this.referentialsService.deleteVersion(this.referential.id, version.version)
        .subscribe(d => this.refresh());
    }
  }

  downloadDebugModel(pilotId: string) {
    const modelFile = this.getModelDebugFile(pilotId);
    modelFile.generating = true;
    this.referentialsService.getPilotDebug(this.id, pilotId)
      .subscribe(data => {
        console.log('Debug model received');
        const blob = new Blob([data], { type: 'application/xml' });
        modelFile.generating = false;
        modelFile.file = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      }, error => {
        modelFile.generating = false;
        console.log(error);
      });
  }

  downloadDebugIps(pilotId: string) {
    const modelFile = this.getModelDebugIps(pilotId);
    modelFile.generating = true;
    this.referentialsService.getPilotDebugIps(this.id, pilotId)
      .subscribe(data => {
        console.log('Debug ips received');
        const blob = new Blob([data], { type: 'text/csv' });
        modelFile.generating = false;
        modelFile.file = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      }, error => {
        modelFile.generating = false;
        console.log(error);
      });
  }

  downloadModel(version: number) {
    const modelFile = this.getModelFile(version);
    modelFile.generating = true;
    this.referentialsService.getModelFile(this.id, version)
      .subscribe(data => {
        console.log('Debug model received');
        const blob = new Blob([data], { type: 'application/xml' });
        modelFile.generating = false;
        modelFile.file = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      }, error => {
        modelFile.generating = false;
        console.log(error);
      });
  }

  getModelDebugFile(id: string) {
    let modelFile = this.modelDebugFiles[id];
    if (!modelFile) {
      modelFile = new FileDownload();
      this.modelDebugFiles[id] = modelFile;
    }
    return modelFile;
  }

  getModelDebugIps(id: string) {
    let modelFile = this.modelDebugIps[id];
    if (!modelFile) {
      modelFile = new FileDownload();
      this.modelDebugIps[id] = modelFile;
    }
    return modelFile;
  }

  getModelFile(version: number) {
    let modelFile = this.modelFiles[version];
    if (!modelFile) {
      modelFile = new FileDownload();
      this.modelFiles[version] = modelFile;
    }
    return modelFile;
  }

  isValidVersion(version) {
    if (version == null || version === '') {
      return true;
    }
    return !isNaN(version) && version > 0;
  }
}

@Component({
  selector: 'referential-errors-dialog',
  templateUrl: './referential-errors-dialog.html',
  styleUrls: ['./referential-errors-dialog.css']
})
export class ReferentialVersionErrorsDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public errors: string[]) {
  }
}

class FileDownload {

  public generating = false;
  public file: SafeResourceUrl;

}
