import {Component, Inject, OnInit} from '@angular/core';
import {Process, ProcessService} from "./process.service";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalService} from "../../global.service";
import {OnDestroy} from '@angular/core';
import {ProcessSearch} from "./process.service";
import {MAT_DIALOG_DATA, MatDialog, MatSnackBar} from "@angular/material";
import {ReferentialVersionErrorsDialog} from "../../instantlca/referential/referential-detail.component";

@Component({
  selector: 'processes',
  templateUrl: './processes.component.html',
  styleUrls: ['./processes.component.scss']
})
export class ProcessesComponent implements OnInit, OnDestroy {

  processes: Process[];
  dataset: string;
  displayedColumns: string[] = ['uuid', 'name', 'activity', 'metadata', 'actions'];
  search = new ProcessSearch;

  constructor(private processeService: ProcessService, private route: ActivatedRoute, private router: Router, private globalService: GlobalService,
              private snackBar: MatSnackBar, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dataset = params['id'];
      this.globalService.setDataset(this.dataset);
    });
  }

  ngOnDestroy() {
  }

  refresh() {
    this.processeService.search(this.dataset, this.search)
      .subscribe(m => this.processes = m)
  }

  showMetadata(p: Process) {
    this.dialog.open(ProcessMetadataDialog, {data: p});
  }

  deleteProcess(row: Process) {
    if (confirm("Are you sure to delete " + row.metadata.name)) {
      this.processeService.deleteProcess(this.dataset, row.uuid)
        .subscribe(d => {
          this.refresh();
          this.snackBar.open(d.message, null, {
            duration: 1000
          });
        });
    }
  }
}

@Component({
  selector: 'process-metadata-dialog',
  templateUrl: './process-metadata-dialog.html',
  styleUrls: ['./process-metadata-dialog.css']
})
export class ProcessMetadataDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public process: Process) {
  }
}
