import {Component, OnInit} from '@angular/core';
import {ImportDetail, ImportsService, ImportStatusDetailElement, MissingFlow} from "./imports.service";
import {ActivatedRoute, Router} from "@angular/router";
import {interval} from "rxjs/internal/observable/interval";
import {GlobalService} from "../global.service";

@Component({
  selector: 'import-detail',
  templateUrl: './importdetail.component.html',
  styleUrls: ['./importdetail.component.css']
})
export class ImportDetailComponent implements OnInit {

  private sub: any;
  private dataset: string;
  private id: string;
  import: ImportDetail;
  displayedColumns: string[] = ['icon', 'date', 'phase', 'status', 'error'];
  detailColumns: string[] = ['item', 'imported', 'skipped', 'total'];
  missingFlowsColumns = [
    {
      data: 'flowType',
      readOnly: true,
      title: "Flow type"
    },
    {
      data: 'sourceId',
      readOnly: true,
      title: "Source id"
    },
    {
      data: 'flowId',
      readOnly: true,
      title: "Flow Id"
    },
    {
      data: 'description',
      readOnly: true,
      title: "Description"
    }
  ]
  detail: Object[];
  missingFlows: MissingFlow[];

  constructor(private globalService: GlobalService, private importService: ImportsService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.dataset = params['id'];
      this.globalService.setDataset(this.dataset);
      this.id = params['importId'];
      this.refresh();
      interval(5000).subscribe(() => this.refresh());
    });
  }

  private refresh() {
    this.importService.getImportDetail(this.dataset, this.id).subscribe(i => {
      this.import = i
      if (i.statusDetail) {
        this.detail = [];
        this.detail.push(this.getItem('Processes', this.import.statusDetail.processes));
        this.detail.push(this.getItem('Flows', this.import.statusDetail.flows));
        this.detail.push(this.getItem('Methods', this.import.statusDetail.methods));
        this.missingFlows = i.statusDetail.missingFlows
      }
    });
  }

  private getItem(title: string, det: ImportStatusDetailElement) {
    return {
      item: title,
      imported: det.imported,
      skipped: det.skipped,
      total: det.count
    };
  }

  deleteImport() {
    this.importService
      .deleteImport(this.dataset, this.id)
      .subscribe(i => this.router.navigate(['imports', this.dataset]));
  }
}
