import {Component, OnInit} from '@angular/core';
import {Method, MethodService} from "./method.service";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalService} from "../../global.service";
import {interval} from 'rxjs';
import {OnDestroy} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'methods',
  templateUrl: './methods.component.html'
})
export class MethodsComponent implements OnInit, OnDestroy {

  methods: Method[];
  dataset: string;
  displayedColumns: string[] = ['uuid', 'name', 'lcia', 'actions'];
  csvUrl: any;
  csvGenerating: boolean;

  private sub: any;

  constructor(private methodService: MethodService, private route: ActivatedRoute, private router: Router, private globalService: GlobalService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dataset = params['id'];
      this.globalService.setDataset(this.dataset);
      this.refresh();
      this.sub = interval(5000).subscribe(() => this.refresh());
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private refresh() {
    this.methodService.getMethods(this.dataset)
      .subscribe(m => this.methods = m)
  }

  deleteMethod(row: Method) {
    if (confirm("Are you sure to delete " + row.metadata.name)) {
      this.methodService.deleteMethod(this.dataset, row.uuid)
        .subscribe(d => this.refresh());
    }
  }

  computeLcia(row: Method) {
    this.methodService.computeLcia(this.dataset, row.uuid)
      .subscribe(d => this.refresh());
  }

  downloadLcias() {
    this.csvUrl = null;
    this.csvGenerating = true;
    this.methodService.downloadLcias(this.dataset)
      .subscribe(data => {
        const blob = new Blob([data], { type: 'text/csv' });
        this.csvUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        this.csvGenerating = false;
      }, error => {
        this.csvUrl = null;
        this.csvGenerating = false;
      });
  }
}



