import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Import, ImportRequest, ImportsService } from "./imports.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ImportsDataSource } from "./imports.datasource";
import { MatExpansionPanel, MatHorizontalStepper } from "@angular/material";
import { interval } from "rxjs/internal/observable/interval";
import { GlobalService } from "../global.service";

@Component({
  selector: "imports",
  templateUrl: "./imports.component.html",
  styleUrls: ["./imports.component.css"],
})
export class ImportsComponent implements OnInit, OnDestroy {
  public imports;

  private sub: any;
  dataset: string;

  dataSource: ImportsDataSource;
  displayedColumns: string[] = ["icon", "date", "type", "phase", "status"];
  newImport: ImportRequest = new ImportRequest();

  @ViewChild("stepper") stepper: MatHorizontalStepper;
  @ViewChild("newImportPanel") newImportPanel: MatExpansionPanel;

  constructor(
    private importService: ImportsService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.newImport.flows.include = false;
    this.dataSource = new ImportsDataSource(this.importService);
    this.route.params.subscribe((params) => {
      this.dataset = params["id"];
      this.globalService.setDataset(this.dataset);
      this.refresh();
      this.sub = interval(5000).subscribe(() => this.refresh());
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getRecord(row: Import) {
    this.router.navigate(["imports", this.dataset, row.id]);
  }

  getSourcePlaceholder(): string {
    return "ZIP file URL";
  }

  isEcoSpold(): boolean {
    return this.newImport.type == "ECOSPOLD";
  }

  refresh() {
    this.dataSource.loadImports(this.dataset);
  }

  startImport() {
    this.importService
      .newImport(this.dataset, this.newImport)
      .subscribe((i) => this.refresh());
    this.newImportPanel.close();
  }
}
