import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule, MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule, MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatToolbarModule
} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {initializer} from './keycloak/app-init';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {DatasetListComponent} from "./dataset/choosedataset.component";
import {RouterModule, Routes} from "@angular/router";
import {DatasetService} from "./dataset/dataset.service";
import {ApiInterceptor} from "./app.urlinterceptor";
import {ImportsService} from "./import/imports.service";
import {ImportsComponent} from "./import/imports.component";
import {ImportDetailComponent} from "./import/importdetail.component";
import 'hammerjs';
import {DatasetDialog} from "./dataset/dataset-dialog";
import {GlobalService} from "./global.service";
import {RefTablesComponent} from "./reftable/ref-tables.component";
import {RefTablesService} from "./reftable/ref-tables.service";
import {RefTableDetailComponent} from "./reftable/ref-table-detail.component";
import {HotTableModule} from "@handsontable/angular";
import {ChooseRefTableComponent} from "./common/choose-ref-table.component";
import {MethodsComponent} from "./data/method/methods.component";
import {MethodService} from "./data/method/method.service";
import {ProcessService} from "./data/process/process.service";
import {ReferentialsComponent} from "./instantlca/referential/referentials.component";
import {ReferentialsService} from "./instantlca/referential/referentials.service";
import {
  ReferentialDetailComponent,
  ReferentialVersionErrorsDialog
} from "./instantlca/referential/referential-detail.component";
import {KeycloakOauthBearerInterceptor} from "./keycloak/app-http-interceptor";
import {ProcessesComponent, ProcessMetadataDialog} from "./data/process/processes.component";

const routes: Routes = [
  { path: '', redirectTo: 'datasets', pathMatch: 'full'},
  { path: 'datasets', component: DatasetListComponent },
  { path: 'imports/:id', component: ImportsComponent },
  { path: 'imports/:id/:importId', component: ImportDetailComponent },
  { path: 'tables/:id', component: RefTablesComponent },
  { path: 'tables/:id/:tableId', component: RefTableDetailComponent },
  { path: 'methods/:id', component: MethodsComponent },
  { path: 'processes/:id', component: ProcessesComponent },
  { path: 'referentials', component: ReferentialsComponent },
  { path: 'referentials/:id', component: ReferentialDetailComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    DatasetListComponent,
    ImportsComponent,
    ImportDetailComponent,
    DatasetDialog,
    RefTablesComponent,
    RefTableDetailComponent,
    ChooseRefTableComponent,
    MethodsComponent,
    ProcessesComponent,
    ReferentialsComponent,
    ReferentialDetailComponent,
    ReferentialVersionErrorsDialog,
    ProcessMetadataDialog
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    KeycloakAngularModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatExpansionModule,
    MatStepperModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatToolbarModule,
    MatDialogModule,
    MatSidenavModule,
    MatMenuModule,
    HotTableModule,
    MatSnackBarModule,
    MatChipsModule
  ],
  entryComponents:
    [DatasetDialog,ReferentialVersionErrorsDialog,ProcessMetadataDialog],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakOauthBearerInterceptor,
      multi: true
    },
    DatasetService,
    ImportsService,
    GlobalService,
    RefTablesService,
    MethodService,
    ProcessService,
    ReferentialsService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
