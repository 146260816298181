import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RefTable, RefTablesService} from "../reftable/ref-tables.service";

@Component({
  selector: 'galaxy-ref-table-combobox',
  template: `<mat-select [value]="value" (valueChange)="setNgModel($event)" [placeholder]="placeholder">
    <mat-option [value]="null">None</mat-option>
    <mat-option *ngFor="let table of tables" [value]="table.id">{{table.name}}</mat-option>
  </mat-select>`
})
export class ChooseRefTableComponent implements OnInit {

  tables: RefTable[];
  @Input() dataset: string;
  @Input() tableType: string;
  @Input() placeholder: string;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  constructor(private tablesService: RefTablesService) {
  }

  setNgModel(newValue) {
    this.value = newValue;
    this.valueChange.emit(newValue);
  }

  ngOnInit(): void {
    this.tablesService.getTables(this.dataset, this.tableType)
      .subscribe(l => this.tables = l);
  }

}
