import {DataSource} from "@angular/cdk/table";
import {BehaviorSubject, Observable, of} from "rxjs";
import {CollectionViewer} from "@angular/cdk/collections";
import {ImportsService} from "./imports.service";
import {catchError, finalize} from "rxjs/operators";

export class ImportsDataSource implements DataSource<any> {

    private lessonsSubject = new BehaviorSubject<Object[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private importsService: ImportsService) {}

    connect(collectionViewer: CollectionViewer): Observable<Object[]> {
      return this.lessonsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
      this.lessonsSubject.complete();
      this.loadingSubject.complete();
    }

    loadImports(dataset: string) {
      this.loadingSubject.next(true);

      this.importsService.getImports(dataset)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(imports => this.lessonsSubject.next(imports));
    }
  }
