import {KeycloakService} from 'keycloak-angular';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const keycloakUrl = (<any>window).GALAXY.keycloak_url;
        const keycloakSecret = (<any>window).GALAXY.keycloak_secret;
        await keycloak.init({
          config: {
            url: keycloakUrl,
            realm: 'home',
            clientId: 'galaxy-angular',
            credentials: {
              secret: keycloakSecret
            }
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          enableBearerInterceptor: false,
          bearerExcludedUrls: [
            '/assets',
            '/clients/public'
          ],
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}
